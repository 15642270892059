import { isNumber, truncate } from 'lodash-es';
import { CuratorEmoji } from '@/components/Curator';
import Icon from '@/components/ui/Icon';
import Separator from '@/components/ui/Separator';
import { CURATOR_RANGE } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { cn, humanReadableDuration } from '@/utils';
const CuratorScore = ({
  curator,
  full,
  hideTitle = false,
  truncateLength = 20,
  className,
  ...props
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    'curator-score': true,
    'curator-score-full': full,
    [className]: className
  });
  const CuratorTitle = () => {
    const {
      rankNormalized
    } = curator;
    if (!isNumber(rankNormalized)) return null;
    if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
      return <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} className="text-3xl" />
          {t('curator.score.levels.highest.title')}
        </h4>;
    } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
      return <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} className="text-3xl" />
          {t('curator.score.levels.high.title')}
        </h4>;
    } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
      return <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} className="text-3xl" />
          {t('curator.score.levels.medium.title')}
        </h4>;
    } else if (rankNormalized >= CURATOR_RANGE.LOW) {
      return <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} className="text-3xl" />
          {t('curator.score.levels.low.title')}
        </h4>;
    } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
      return <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} className="text-3xl" />
          {t('curator.score.levels.lowest.title')}
        </h4>;
    }
    return <h4 className="inline-flex items-center gap-1" data-sentry-component="CuratorTitle" data-sentry-source-file="CuratorScore.jsx">
        <CuratorEmoji curator={curator} data-sentry-element="CuratorEmoji" data-sentry-source-file="CuratorScore.jsx" />
        {t('curator.score.levels.lowest.title')}
      </h4>;
  };
  const CuratorDescription = () => {
    const {
      rankNormalized
    } = curator;
    if (!isNumber(rankNormalized)) return null;
    if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
      return <p>{t('curator.score.levels.highest.text')}</p>;
    } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
      return <p>{t('curator.score.levels.high.text')}</p>;
    } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
      return <p>{t('curator.score.levels.medium.text')}</p>;
    } else if (rankNormalized >= CURATOR_RANGE.LOW) {
      return <p>{t('curator.score.levels.low.text')}</p>;
    } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
      return <p>{t('curator.score.levels.lowest.text')}</p>;
    }
    return t('curator.score.levels.lowest.text');
  };
  return <div className={classes} {...props} data-sentry-component="CuratorScore" data-sentry-source-file="CuratorScore.jsx">
      {!hideTitle && <h4 className="inline-flex items-center gap-1">
          <CuratorEmoji curator={curator} />
          {curator?.spotifyUsername ? truncate(curator.spotifyUsername, {
        length: truncateLength
      }) : truncate(curator?.id, {
        length: truncateLength
      })}
        </h4>}

      {full && <CuratorTitle />}
      {full && <CuratorDescription />}

      <div className="curator-score-data">
        {curator?.responseRate > 0 && <span>
            <Icon name="MessageCircle" />
            {full && <span>
                {t('curator.score.responseRate')}:{' '}
              </span>}
            {(curator?.responseRate * 100).toFixed(0)}%
          </span>}

        {!curator?.responseRate && <span>
            <Icon name="MessageCircle" />
            {full && <span>
                {t('curator.score.responseRate')}:{' '}
              </span>}
            {t('curator.score.noData')}
          </span>}

        {!curator?.timeMean && <span>
            <Icon name="Clock" />
            {full && <span>
                {t('curator.score.timeMean')}:{' '}
              </span>}
            {t('curator.score.noData')}
          </span>}

        {curator?.timeMean > 0 && <span>
            <Icon name="Clock" />
            {full && <span>
                {t('curator.score.timeMean')}:{' '}
              </span>}
            {humanReadableDuration({
          value: curator?.timeMean,
          locale,
          t
        })}
          </span>}
      </div>

      {full && <div>
          <Separator className="mb-4" />
          <p className="text-sm font-light opacity-70">
            {t('curator.score.disclaimer')}
          </p>
        </div>}
    </div>;
};
export default CuratorScore;