import Button from '@/components/ui/Button';
import { useTranslations } from '@/hooks';
export function ProRequestDisclaimer({
  onConfirm
}) {
  const t = useTranslations();
  return <div className="space-y-6" data-sentry-component="ProRequestDisclaimer" data-sentry-source-file="ProRequestDisclaimer.jsx">
      <div>
        <h3 className="mb-2 text-lg font-medium">
          {t('push_pro.disclaimer.title')}
        </h3>
        <p className="text-sm text-muted-foreground">
          {t('push_pro.disclaimer.description')}
        </p>
      </div>

      <div>
        <h4 className="mb-2 text-lg font-medium">
          {t('push_pro.disclaimer.who_title')}
        </h4>
        <p className="text-sm text-muted-foreground">
          {t('push_pro.disclaimer.who_description')}
        </p>
      </div>

      <div>
        <h4 className="mb-2 text-lg font-medium">
          {t('push_pro.disclaimer.examples_title')}
        </h4>
        <ul className="pl-6 space-y-1 text-sm list-disc text-muted-foreground">
          {Array.from({
          length: 8
        }).map((_, i) => <li key={i}>{t(`push_pro.disclaimer.examples.${i}`)}</li>)}
        </ul>
      </div>

      <div>
        <h4 className="mb-2 text-lg font-medium">
          {t('push_pro.disclaimer.evaluation_title')}
        </h4>
        <ul className="pl-6 space-y-1 text-sm list-disc text-muted-foreground">
          {Array.from({
          length: 4
        }).map((_, i) => <li key={i}>{t(`push_pro.disclaimer.evaluation_criteria.${i}`)}</li>)}
        </ul>
      </div>

      <p className="text-sm text-muted-foreground">
        {t('push_pro.disclaimer.footer')}
      </p>

      <Button className="w-full" variant="blue" onClick={onConfirm} label={t('push_pro.disclaimer.confirm_button')} data-sentry-element="Button" data-sentry-source-file="ProRequestDisclaimer.jsx" />
    </div>;
}
export default ProRequestDisclaimer;