import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ProRequestDisclaimer } from './ProRequestDisclaimer';
import { matchfyApi } from '@/api';
import Auth from '@/components/Auth';
import ContactForm from '@/components/ContactForm/ContactForm';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/Dialog';
import Empty from '@/components/ui/Empty';
import { CONTACT_TYPES, QUERIES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { isPro } from '@/utils';
export function ProRequestForm({
  message,
  useDialog = true,
  onClose
}) {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(!useDialog);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const {
    data: userProRequest,
    isSuccess
  } = useQuery({
    queryKey: [QUERIES.PUSH_PRO.REQUEST],
    queryFn: () => matchfyApi.getRequest(CONTACT_TYPES.PRO)
  });
  const t = useTranslations();
  const {
    mutate: sendContactRequest,
    isSuccess: isSendSuccess
  } = useMutation({
    mutationFn: data => matchfyApi.sendContactRequest({
      formData: data,
      type: CONTACT_TYPES.PRO
    })
  });
  const handleSubmit = data => {
    try {
      sendContactRequest(data);
      toast('success', t('feedback.success.contact_form.sent'));
      if (useDialog) {
        setShowContactForm(false);
      } else if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleConfirmDisclaimer = () => {
    setShowDisclaimer(false);
    setShowContactForm(true);
  };
  const ExistingProRequest = () => <Empty className="my-0" variant="yellow" icon="Hourglass" message={t('banner.pro.notice')} data-sentry-element="Empty" data-sentry-component="ExistingProRequest" data-sentry-source-file="ProRequestForm.jsx" />;
  const RequestSent = () => <Empty className="my-0" variant="green" icon="CircleCheck" message={t('feedback.success.contact_form.sent')} data-sentry-element="Empty" data-sentry-component="RequestSent" data-sentry-source-file="ProRequestForm.jsx" />;
  const getContactForm = () => {
    if (isSendSuccess) {
      return <RequestSent />;
    }
    if (userProRequest && !userProRequest?.isExpired) {
      return <ExistingProRequest />;
    }
    if (showDisclaimer) {
      return <ProRequestDisclaimer onConfirm={handleConfirmDisclaimer} />;
    }
    return <Auth login emptyState data-sentry-element="Auth" data-sentry-component="getContactForm" data-sentry-source-file="ProRequestForm.jsx">
        <ContactForm submitBtnVariant="blue" messageLabel={t('banner.pro.form.message.label')} messagePlaceholder={t('banner.pro.form.message.placeholder')} user={user} onSubmit={handleSubmit} disabled={isSuccess ? userProRequest && !userProRequest?.isExpired : false} data-sentry-element="ContactForm" data-sentry-source-file="ProRequestForm.jsx" />
      </Auth>;
  };
  return <>
      {!isPro(user?.type) && useDialog && <Alert inline variant="blue" size="sm" className="py-2 pl-4 pr-2 mb-6" cta={<Button size="xs" icon="ArrowRight" onClick={() => setShowDisclaimer(true)} variant="blue" className="w-full md:w-auto">
              {t('banner.pro.actions.become_pro')}
            </Button>}>
          <p className="pr-4 text-sm">
            {message}
          </p>
        </Alert>}

      {useDialog ? <Dialog open={showDisclaimer || showContactForm} onOpenChange={open => {
      setShowDisclaimer(open ? showDisclaimer : false);
      setShowContactForm(open ? showContactForm : false);
    }}>
          <DialogContent className={showDisclaimer ? 'max-w-2xl' : undefined}>
            <DialogHeader>
              <DialogTitle>
                {t('banner.pro.form.title')}
              </DialogTitle>
              <DialogDescription className="mt-3">
                {t('banner.pro.form.description')}
              </DialogDescription>
            </DialogHeader>

            {getContactForm()}
          </DialogContent>
        </Dialog> : getContactForm()}
    </>;
}
export default ProRequestForm;