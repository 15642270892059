import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { useTranslations } from '@/hooks';
import { EVENTS, mxp } from '@/lib';
import { cn } from '@/utils';
export const ContactForm = ({
  messageLabel,
  messagePlaceholder,
  submitBtnVariant,
  user,
  className,
  disabled,
  onSubmit
}) => {
  const [loading, setLoading] = useState(false);
  const t = useTranslations();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: {
      name: user?.billingInfo?.firstName,
      lastName: user?.billingInfo?.lastName,
      email: user?.contacts?.email,
      phone: user?.billingInfo?.phone?.charAt(0) === '+' ? user?.billingInfo?.phone ?? '' : `+${user?.billingInfo?.phone ?? ''}`,
      message: ''
    }
  });
  const onSubmitForm = data => {
    setLoading(true);
    if (onSubmit) {
      try {
        onSubmit(data);
        mxp.track(EVENTS.CONTACT_FORM_SUBMISSION, data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const classes = cn({
    'grid grid-cols-1 md:grid-cols-2 gap-4': true,
    [className]: className
  });
  return <form id="contact-form" className={classes} onSubmit={handleSubmit(onSubmitForm)} data-sentry-component="ContactForm" data-sentry-source-file="ContactForm.jsx">
      <Controller name="name" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="text" label={t('field.firstName.label')} required disabled={disabled} errors={errors.name} placeholder={t('field.firstName.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="ContactForm.jsx" />

      <Controller name="lastName" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="text" label={t('field.lastName.label')} required disabled={disabled} errors={errors.lastName} placeholder={t('field.lastName.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="ContactForm.jsx" />

      <Controller name="email" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="email" label={t('field.email.label')} required disabled={disabled} errors={errors.email} placeholder={t('field.email.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="ContactForm.jsx" />

      <div className="form-group">
        <label htmlFor="billing-phone">
          {t('field.phone.label')}
        </label>
        <Controller name="phone" control={control} rules={{
        required: true
      }} render={({
        field: {
          onChange,
          value
        }
      }) => <PhoneInput placeholder={t('field.phone.label')} defaultCountry={!value ? user?.billingInfo?.country : ''} value={value} onChange={onChange} disabled={disabled} />} data-sentry-element="Controller" data-sentry-source-file="ContactForm.jsx" />
      </div>

      <Controller name="message" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="textarea" label={messageLabel ?? t('field.message.label')} required disabled={disabled} errors={errors.message} placeholder={messagePlaceholder ?? t('field.message.placeholder')} className="md:col-span-2" {...field} />} data-sentry-element="Controller" data-sentry-source-file="ContactForm.jsx" />

      <Button type="submit" loading={loading} variant={submitBtnVariant} disabled={disabled} label={t('common.actions.send', {
      entity: 'request'
    })} className="md:col-span-2" data-sentry-element="Button" data-sentry-source-file="ContactForm.jsx" />
    </form>;
};
export default ContactForm;